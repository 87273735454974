import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { SliderService } from '@services/slider.service';
import { AdvertisingModel } from '@models/advertising-model';
import { AdvertisingFacadeService } from '../advertising/advertising-facade.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnDestroy {
  customOptions: OwlOptions = {
    loop: false,
    autoplay: true,
    center: false,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    nav: false,
    navText: [
      '<i class="fas fa-chevron-left"></i>',
      '<i class="fas fa-chevron-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  sliders = [
    {
      backgroundClass: 'main-slider',
    },
    {
      backgroundClass: 'main-slider',
    },
    {
      backgroundClass: 'main-slider',
    },
  ];
  public advertisingAll: AdvertisingModel[] = [];
  subs: Subscription = new Subscription();
  urlVideo: '../../../../assets/img/giovanye/desktop';
  mute: boolean = false;
  icon: string = 'volume_up';
  public titleLine1: string = 'The Sculpture of Creation'
  public titleLine2: string = '23/24'
  public textButtonLeft = 'MEN'
  public textButtonRith = 'WOMEN'
  constructor(
    private advertisingFacade: AdvertisingFacadeService,
    private sanitizer: DomSanitizer
  ) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.advertisingAll || this.advertisingAll.length <= 0) {
      let sub: Subscription = this.advertisingFacade
        .getAdvertisingAll$()
        .subscribe((advertisingAll) => {
          this.advertisingAll = advertisingAll.filter(
            (e: any) => e.section == 'webMain' && e.status == 1
          );
        });
      this.subs.add(sub);
    }
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  muted() {
    this.mute = !this.mute;
    if (!this.mute) {
      this.icon = 'volume_up';
    } else {
      this.icon = 'volume_off';
    }
  }
}
