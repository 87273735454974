import { ISliderPromo } from './../../../core/interfaces/slider-promo-interface';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { UtilsService } from './../../../utilities/utils.service';
import { NavService } from './nav.service';

import { UserMenuList } from './../user-dropdown/user-menu-list.interface';
import { userMenuListData } from './../../../test-helpers/user-menu-list';
import { UserFacadeService } from '@facades/auth/user-facade.service';
import { UserModel } from '@models/user.model';
import { AddressFacadeService } from '@root/features/delivery-address/delivery-address-facade.service';
import { CategoriesFacadeService } from '../categories/categories-facade.service';
import { CategoryInterface } from '../categories/store/categories.actions';
import { ProductsService } from '@services/products.service';
import { sliderPromoMocks } from '@root/core/mocks/slider-promo-mocks';
import Swal from 'sweetalert2';
import { MessageService } from '@services/message.service';
import { FormControl } from '@angular/forms';
import { tap, debounceTime, map } from 'rxjs/operators';
import { menuCategoryMocks } from '@root/core/mocks/menu-categories-mocks';
import { CartFacadeService } from '@root/pages/cart/cart-facade.service';
import { NavFacadeService } from './nav.facade';
import {
  CategoriesResponse,
  NavCategories,
} from '@interfaces/categories.response.interface';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Input() dark: boolean = false;
  @Input() promo = sliderPromoMocks;
  @Input() menuNavCate = menuCategoryMocks;
  @ViewChild('itemMenu') item: ElementRef;
  public activeMenu = false;
  public subscription: Subscription;
  public isOpenMenu = false;
  public isActive = false;
  public isLoggedIn = false;
  public isOpenMenuDesktop = false;
  public options: UserMenuList[] = userMenuListData;
  public user: UserModel;
  public AddressShipping: any = { name: 'Elija una Direccion' };
  public addressShippingName = 'Elija la direccion';
  public AddressesShippingAll: any[] = [];
  public subs = new Subscription();
  public titulo = 'nav.shipping-address';
  public cart: any;
  public itemsCart: number;
  public categories: CategoryInterface[] = [];
  public sliderPromo: ISliderPromo;
  public searchBox: boolean = true;
  public styleClass: string =
    'searchBox hide animate__animated animate__fadeInRight';
  public productsFound: any[] = [];
  userData = {
    name: 'David Henao',
    image: null,
  };

  public search = new FormControl('');
  public showSpinner: boolean = false;
  public allCarouselCategories: CategoriesResponse;
  public lastCategory: string;
  public productsCategories: NavCategories[];
  public addClass: boolean = false;
  public urlbase = environment.STORAGE;
  constructor(
    private toggleMenuEventService: NavService,
    public dialog: MatDialog,
    private router: Router,
    private utilsService: UtilsService,
    private userFacade: UserFacadeService,
    private addressFacade: AddressFacadeService,
    private cartFacade: CartFacadeService,
    private categoriesFacade: CategoriesFacadeService,
    private productsService: ProductsService,
    private messageService: MessageService,
    private navFacade: NavFacadeService
  ) { }

  ngOnInit() {
    this.navFacade.getNavCategories();
    this.subscription =
      this.toggleMenuEventService.toggleMenuEvent.subscribe(
        (isOpen: boolean) => {
          this.isOpenMenu = isOpen;
        }
      );

    this.getDatos();
    this.productsService.getCategoriesTree$().subscribe((categories) => {
      this.categories = [
        ...categories
      ];
    });
    this.infoUser();
    this.search.valueChanges
      .pipe(
        tap((e: any) => {
          this.showSpinner = true;
        }),
        debounceTime(1000),
        map((e: any) => {
          this.searchProduct(e);
          this.showSpinner = false;
        })
      )
      .subscribe();
    this.subScriptions();
    this.getCategoryAutom();
  }
  subScriptions() {
    const sub = this.navFacade
      .getNavcategoriesFromStore$()
      .subscribe((categories) => {
        if (!categories) return;
        this.allCarouselCategories = {
          product: [],
          date: '',
        };
        this.allCarouselCategories = categories
      });
    this.subs.add(sub);
  }
  closeMenu() {
    this.isOpenMenu = false;
  }
  getDatos() {
    this.subs.add(this.subscription);
    let sub: Subscription = this.userFacade
      .getCurrentUser$()
      .subscribe((user) => {
        this.user = user;
        this.isLoggedIn = user !== null;
        if (user == null) {
          this.isOpenMenuDesktop = false;
        }
      });
    this.subs.add(sub);

    let sub2: Subscription = this.addressFacade
      .getCurrentAddressShipping$()
      .subscribe((address) => {
        this.AddressShipping = address;
        if (this.AddressShipping) {
          this.addressShippingName = this.AddressShipping.name;
        }
      });
    this.subs.add(sub2);

    let sub3: Subscription = this.addressFacade
      .getAddressesShipping$()
      .subscribe((addressesAll: any) => {
        this.addressShippingName = 'Elija la direccion';
        if (!addressesAll || addressesAll.length <= 0) {
          this.addressShippingName = 'Registre una Dirección';
          this.AddressesShippingAll = [];
          return;
        }
        this.AddressesShippingAll = [...addressesAll];
      });
    this.subs.add(sub3);
    let sub4: Subscription = this.cartFacade
      .getCart$()
      .subscribe((items) => {
        localStorage.setItem('cart', JSON.stringify(this.cart))
        if (items) {
          this.itemsCart = 0;
          items.forEach((element) => {
            this.itemsCart += element.qty;
          });
        }
      });
    this.subs.add(sub4);
  }
  setAddress(addressSelected) {
    this.addressFacade.setAddressShipping(addressSelected);
  }
  /**
   * Toggle Menu
   */
  toggleUserMenuDesktop(): void {
    this.isOpenMenuDesktop = !this.isOpenMenuDesktop;
  }

  toggleMenu() {
    this.activeMenu = !this.activeMenu;
  }
  searchOffers() {
    this.isOpenMenu = false;
    this.utilsService.loading();
    this.router.navigateByUrl('/articulos');
  }
  public redirecto() {
    if (
      !this.AddressesShippingAll ||
      this.AddressesShippingAll.length <= 0
    ) {
      this.router.navigateByUrl('/edit-direcciones-de-entrega');
    }
  }
  categorySelected(item) {
    if (!item) return;

    this.categoriesFacade.setCurrentCategory(item);
  }

  promoNav: OwlOptions = {
    loop: true,
    autoplay: true,
    center: false,
    dots: true,
    autoHeight: false,
    autoWidth: false,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
    },
  };

  infoUser(): void {
    let sub: Subscription = this.userFacade
      .getCurrentUser$()
      .subscribe((resp) => {
        if (resp) {
          this.userData = {
            name: resp.name,
            image: resp.image,
          };
        } else {
          this.userData = {
            name: null,
            image: null,
          };
        }
      });

    this.subs.add(sub);
  }

  toggleMenu2() {
    this.activeMenu = !this.activeMenu;
  }

  logOut() {
    Swal.fire({
      title: 'SESION',
      html: '¿Desea cerrar su sesión?',
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: 'Si',
      confirmButtonAriaLabel: '',
      cancelButtonText: 'No',
      denyButtonText: `Epayco`,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn negro', //SisteCredito
        denyButton: 'btn negro', // Epayco
        cancelButton: 'btn gris', // Cancelar
      },
    }).then((respons) => {
      if (respons.isConfirmed) {
        this.router.navigateByUrl('/logout');
      } else if (respons.isDenied) {
      } else if (respons.isDismissed) {
      }
    });
  }
  showSearchBox() {
    if (this.searchBox) {
      this.searchBox = false;
      this.styleClass =
        'searchBox show animate__animated animate__fadeInRight';
    } else {
      this.styleClass =
        'searchBox animate__animated animate__fadeOutRightBig';
      this.searchBox = true;
    }
  }

  searchProduct(query: string) {
    if (query.trim() === '') return;
    this.showSpinner = true;
    this.productsFound = [];
    this.productsService
      .getProductsByQuery$(query)
      .subscribe((response) => {
        if (response.data && response.data.length > 0) {
          this.productsFound = [...response.data];
        } else {
          this.messageService.showCustom(
            `Sin resultados para la palabra '${query}'`,
            null,
            'error'
          );
        }
        this.showSpinner = false;
      });
  }
  onClickSearch(productId: number) {
    this.showSearchBox();
    this.productsFound = [];
    const url = `/articulo/${productId}`;
    this.router.navigateByUrl(url);
  }
  goto(query: string) {
    if (query.trim() === '') return;
    this.productsFound = [];
    const url = `/lista-articulos/${query}`;
    this.router.navigate(['/']);
    this.router.navigate([url]);
  }

  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    center: false,
    dots: true,
    mouseDrag: true,
    // autoHeight: true,
    // autoWidth: true,
    nav: false,
    navText: [
      '<i class="fas fa-chevron-left"></i>',
      '<i class="fas fa-chevron-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  onHover(category: any) {
    if (!this.allCarouselCategories || !category || category === '') return;
    this.productsCategories = [];
    let images = [];
    const search = this.allCarouselCategories.product.filter(
      (e) => e.parent_id == category.parent_id
        && e.name?.toUpperCase() == category?.name.toUpperCase()
        && e.slug?.toUpperCase() == category?.slug.toUpperCase()
    );

    search.forEach(prod => {
      images = [...images, ...prod.image]
    });

    this.productsCategories = [...images];
    this.lastCategory = category;
  }

  getCategoryAutom() {
    setInterval(() => {
      this.navFacade.getNavCategories();
    }, 120000);
  }

  onMenuClick() {
    this.addClass = true
  }
  onHoverMenu() {
    this.productsCategories = [];
    this.addClass = false
  }

}
