import { ProductHgi } from './../../../core/interfaces/store.interface';
import { ProductsService } from '@services/products.service';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterContentChecked,
  AfterViewChecked,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterContentInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { environment } from '@environments/environment.prod';
import { UserFacadeService } from '@facades/auth/user-facade.service';
import { Product } from '@interfaces/featuredproducts';
import { productMocks } from '@root/core/mocks/card-products-mocks';
import { UtilsService } from '@root/utilities/utils.service';
import { MessageService } from '@services/message.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subscription } from 'rxjs';
import { CartItemModel } from '../../../core/models/cart-item.model';
import { productDescriptionMocks } from '@root/core/mocks/product-description-mock';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductAddCartComponent } from '@root/features/product-add-cart/product-add-cart.component';
import { SizesButtonsComponent } from './components/sizes-buttons/sizes-buttons.component';

@Component({
  selector: 'app-product-description',
  templateUrl: './product-description.component.html',
  styleUrls: ['./product-description.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProductDescriptionComponent
  implements
  OnInit,
  AfterContentChecked,
  AfterViewChecked,
  OnDestroy,
  AfterContentInit {
  @ViewChild('imageParentMain') imageParentMain: ElementRef;
  @ViewChild('imageParent') imageParent: ElementRef;
  @ViewChild('image') image: ElementRef;
  @ViewChild('rangeInput') range: ElementRef;
  @ViewChild('glass') glass: ElementRef;
  @ViewChild('eventLayer') eventLayer: ElementRef;
  @Input() existencia: number = 0;
  @Input() productos: Product;
  public products = productMocks;
  @Input() product: any;
  @Output() qtyEmmiter = new EventEmitter<CartItemModel>();
  @Output() orderType = new EventEmitter<number>();
  public quantity: number;
  public imagenes: string[] = [];
  public imgSelected: string;
  public isbadget: boolean = false;
  public hideIcon: boolean = true;
  public details: string = '';
  public user: any;
  public subs: Subscription = new Subscription();
  public urlImage = environment.STORAGE;
  public sizes = [
    { id: 1, size: 'S' },
    { id: 2, size: 'M' },
    { id: 3, size: 'L' },
    { id: 4, size: 'XL' },
  ];
  public panelDescriptions = productDescriptionMocks;
  public panelOpenState = false;
  public colorAll = [
    {
      name: 'blue',
      code: '#233657',
      selected: true,
      id: 1,
    },
    {
      name: 'red',
      code: '#AC4848',
      selected: true,
      id: 2,
    },
    {
      name: 'redVine',
      code: '#833332',
      selected: true,
      id: 3,
    },
    {
      name: 'oranjeSoft',
      code: '#CD9E80',
      selected: true,
      id: 4,
    },
  ];
  public colors = [];
  public colorSelected: string;
  private colorId: number;
  private tallaId: number;
  public availableProduct: boolean = true;
  public maxAvailableProduct: number = 1;
  public loading: boolean = false;
  public productHgi: ProductHgi;
  public showMagnifier: boolean = false;
  public boolEdit: boolean = false;
  constructor(
    private utilService: UtilsService,
    private router: Router,
    private userFacade: UserFacadeService,
    public dialog: MatDialog,
    private productsService: ProductsService,
    private messageService: MessageService,
    private modalService: NgbModal
  ) { }
  ngAfterContentInit(): void { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  ngAfterViewChecked(): void { }
  orderTypeSelected(orderT: boolean) {
    this.isbadget = orderT;
    if (orderT) {
      this.orderType.emit(1);
    } else {
      this.orderType.emit(0);
    }
  }
  ngOnInit(): void {
    this.colors = [...this.colorAll];
    this.orderTypeSelected(this.product.type_of_sale === 1);
    if (this.product.qty > 1) {
      this.quantity = this.product.qty;
    } else {
      this.quantity = 1;
    }
    this.imgSelected =
      this.product.image || 'http://api.obbro.co/storage/shared/noImage.jpeg';
    if (!this.product.carousel) {
      this.ArrayImg();
    } else {
      this.product.carousel.forEach((element: any) => {
        this.imagenes.push(this.urlImage + element);
      });
    }
    this.inicio();
    this.Suscripciones();

    this.hideIcon = false;
    this.sizes = [];
    this.product.sizes.forEach((element: any) => {
      this.sizes.push({
        id: element.id,
        size: element.size,
      });
    });
    this.colors = [];
    this.colorAll = [];
    this.product.colors.forEach((element: any) => {
      this.colorAll.push({
        name: element.name,
        code: element.code,
        selected: true,
        id: element.id,
      });
      this.colors.push({
        name: element.name,
        code: element.code,
        selected: true,
        id: element.id,
      });
    });
  }
  onMouseMove(ev: MouseEvent) {
    const imgWidth = this.image.nativeElement.offsetWidth;
    const imgHeigth = 470;
    const glassRadius = 80;
    const imgBorder = 0;
    const m = 2;
    let imageParentMain =
      this.imageParentMain.nativeElement.offsetLeft > 0
        ? this.imageParentMain.nativeElement.offsetLeft - 90
        : this.imageParentMain.nativeElement.offsetLeft - 40;

    let imgLeft = this.image.nativeElement.offsetLeft + imgBorder;
    let imgTop = this.imageParentMain.nativeElement.offsetTop + imgBorder;

    const newPosX = ev.x - imgLeft - imageParentMain;
    const newPosY = ev.pageY - imgTop;

    const ajst = this.imageParentMain.nativeElement.offsetLeft > 0 ? 0 : +20;
    const newposZoomX = newPosX - 100;
    const newposZoomY = newPosY - 40 + ajst;


    if (
      newPosX - 90 > imgWidth ||
      newPosX - 90 < this.imageParentMain.nativeElement.offsetLeft - 214 ||
      ev.pageY - 40 < imgTop ||
      ev.pageY - 40 > imgTop + imgHeigth
    ) {
      this.showMagnifier = false;
      return;
    } else {
      this.showMagnifier = true;
    }
    this.glass?.nativeElement.style.setProperty(
      'background-size',
      `${imgWidth * m}px
        ${imgHeigth * m}px`
    );

    this.glass.nativeElement.style.setProperty('left', `${newPosX - 90}px`);

    this.glass.nativeElement.style.setProperty('top', `${newPosY - 40}px`);
    this.glass.nativeElement.style.setProperty(
      'background-position',
      `${glassRadius - newposZoomX * m}px
            ${glassRadius - newposZoomY * m}px`
    );
  }

  Suscripciones() {
    let sub: Subscription = this.userFacade
      .getCurrentUser$()
      .subscribe((user) => (this.user = user));
    this.subs.add(sub);
  }
  get price() {
    return this.product.sale_price > 0
      ? this.product.sale_price
      : this.product.regular_price;
  }
  ArrayImg(): void {
    for (let i = 0; i <= 2; i++) {
      this.imagenes.push(this.product.image);
    }
  }
  ngAfterContentChecked() {
    if (this.product.qty > 0 && this.quantity <= 0) {
      this.quantity = this.product.qty;
    }
  }

  cantidad(cantidad: number) {
    if (this.maxAvailableProduct <= this.quantity) return;
    if (this.quantity + cantidad >= 0) {
      this.quantity = this.quantity + cantidad;
    }
    // this.getProductAvailability(this.product.sku, this.tallaId, this.colorId);
  }
  tobuy(product: CartItemModel) {
    let color: any;
    let size: any;
    if (this.product.sku && this.tallaId && this.colorId) {
      // this.getProductAvailability(this.product.sku, this.tallaId, this.colorId);
    }
    if (this.quantity <= 0) { this.quantity = 1; }
    if (!this.tallaId) {
      product.size = this.sizes[0].size
      this.tallaId = this.sizes[0].id
    }
    size = this.sizes.filter(e => e.id === this.tallaId)[0].size
    if (!this.colorSelected) {
      this.product.color = this.colorAll[0].code;
      this.colorSelected = this.colorAll[0].code;
      this.colorId = this.colorAll[0].id;
    }
    color = this.colorSelected
    const item = {
      ...product,
      regular_price: this.price,
      qty: this.quantity,
      color,
      size
    };
    this.qtyEmmiter.emit(item);
    this.dialog.open(ProductAddCartComponent);
  }

  regresar(): void {
    this.router.navigateByUrl(`articulos/${this.product.vendor_id}`);
  }

  cambiaImg(img: any) {
    this.imgSelected = img;
  }

  inicio() {
    window.scroll({
      top: -100,
      left: -100,
      behavior: 'smooth',
    });
  }

  carouselLeft: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplay: false,
    dots: false,
    navSpeed: 300,
    animateOut: 'slideOutUp',
    animateIn: 'slideInUp',
    navText: [
      '<i class="fa-solid fa-arrow-left"></i>',
      '<i class="fa-solid fa-arrow-right"></i>',
    ],
    responsive: {
      0: {
        items: 3,
      },
      992: {
        items: 4,
      },
    },
    nav: true,
  };

  yellow = true;
  btnYellow() {
    this.yellow = !this.yellow;
  }

  blue = true;
  btnBlue() {
    this.blue = !this.blue;
  }

  red = true;
  btnRed() {
    this.red = !this.red;
  }

  piel = true;
  bntPiel() {
    this.piel = !this.piel;
  }


  pickUpInStore() {
    this.utilService.setStores(this.productHgi.CodigoBodega);
  }

  selected(color) {
    this.colors = [...this.colorAll];
    const ColorNew = [];
    let i;
    this.colors.forEach((item) => {
      i = { ...item };
      if (item.name === color.name) {
        i = { ...item, selected: false };
      }
      ColorNew.push(i);
    });

    this.colors = [...ColorNew];
    this.product.color = color.code;
    this.colorSelected = color.code;
    this.colorId = color.id;
    // this.getProductAvailability(this.product.sku, this.tallaId, this.colorId);
  }

  getSize(size): void {
    this.tallaId = size.id;
    // this.getProductAvailability(this.product.sku, this.tallaId, this.colorId);
  }

  getProductAvailability(sku?: string, talla?: number, color?: number): void {
    if (sku == '' || !talla || !color) return;
    this.availableProduct = true;
    const SKU = `${sku}-${talla}-${color}`;
    this.productsService.getProductAvailability$(SKU).subscribe(
      (resp) => {
        this.utilService.setStores(resp.CodigoBodega);
        this.productHgi = { ...resp };
        const qty = resp.Cantidad;
        this.maxAvailableProduct = qty;
        this.availableProduct = qty > 0
      },
      (error) => {
        this.maxAvailableProduct = 0;
        this.availableProduct = false;
        this.loading = false;
        if (typeof error === 'string') {
          ;
        } else {
          this.messageService.showCustom(error.message, null, 'error');
        }
      }
    )
  }

  configProductAvailability() { }

  mover() {
    // );
  }

  showModal(content: any) {
    this.modalService.open(content, { fullscreen: true });
  }
  onSizeSelected(item: any) {
    this.product.size = item.size
    this.getSize(item);
  }
}
